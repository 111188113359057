import PropTypes from 'prop-types';
import React from 'react';

import * as customPropTypes from '@/custom-prop-types';
import { Button } from '@/design-system/atoms/Button';
import { ContentContainer } from '@/design-system/atoms/ContentContainer';
import { Grid, GridColumn } from '@/design-system/atoms/Grid';
import { Image } from '@/design-system/atoms/Image';
import { Text } from '@/design-system/atoms/Text';
import { Theme } from '@/design-system/atoms/Theme';
import { Title } from '@/design-system/atoms/Title';

import styles from './AwardsBanner.module.scss';

/**
 * The Awards component encompasses Atlantic Health's collection of awards.
 */
const AwardsBanner = ({ title, body, cta, awards, ...props }) => {
    const validAwards = Array.isArray(awards) && awards.length > 0;
    const validCta = !!(cta && (cta.href || cta.label));
    const rowCount = validAwards && awards.length > 2 ? 2 : 1;

    const analyticsAwards = React.useMemo(() => {
        const analyticsObj = {
            componentName: 'Awards Banner',
            componentTitle: title,
            interactionType: 'image',
        };
        return awards
            .filter((image) => React.isValidElement(image))
            .map((image) => React.cloneElement(image, { analytics: analyticsObj }));
    }, [awards, title]);

    return (
        <section className={styles[`awards-banner`]} {...props}>
            <Theme name={Theme.NAME.DEFAULT}>
                <ContentContainer>
                    <Grid className={styles[`awards-banner__grid`]} verticalAlignment="center">
                        <GridColumn colSpan={{ sm: 4, md: 3, lg: 6 }}>
                            <div className={styles[`awards-banner__content`]}>
                                <Title
                                    tag="h2"
                                    variant="h3"
                                    content={title}
                                    lineClamp={{ sm: 3, lg: 2 }}
                                />
                                {body && (
                                    <Text
                                        tag="p"
                                        variant="t2"
                                        content={body}
                                        lineClamp={{ sm: 10, lg: 5 }}
                                    />
                                )}
                            </div>
                            {validCta && (
                                <div className={styles[`awards-banner__cta`]}>
                                    <Button
                                        {...cta}
                                        buttonStyle={Button.STYLE.SECONDARY}
                                        fullWidth={{ md: true }}
                                        analytics={{
                                            componentName: 'Awards Banner',
                                            componentTitle: title,
                                        }}
                                    />
                                </div>
                            )}
                        </GridColumn>
                        <GridColumn colSpan={{ sm: 4, md: 3, lg: 6 }}>
                            <Grid
                                className={styles[`awards-banner__awards-grid`]}
                                col={{ sm: 4, md: 6, lg: 6 }}
                                row={{ sm: rowCount, md: rowCount, lg: rowCount }}
                            >
                                {validAwards &&
                                    analyticsAwards.map((image, index) => (
                                        <React.Fragment key={index}>
                                            {image && (
                                                <GridColumn colSpan={{ sm: 2, md: 3, lg: 3 }}>
                                                    <div
                                                        className={
                                                            styles[`awards-banner__award-image`]
                                                        }
                                                    >
                                                        {image}
                                                    </div>
                                                </GridColumn>
                                            )}
                                        </React.Fragment>
                                    ))}
                            </Grid>
                            {validCta && (
                                <div className={styles[`awards-banner__cta-mobile`]}>
                                    <Button {...cta} buttonStyle={Button.STYLE.SECONDARY} />
                                </div>
                            )}
                        </GridColumn>
                    </Grid>
                </ContentContainer>
            </Theme>
        </section>
    );
};

AwardsBanner.propTypes = {
    /**
     * Title of the awards banner - required
     */
    title: PropTypes.string.isRequired,
    /**
     * Description of the awards banner - optional
     */
    body: PropTypes.string,
    /**
     * Call to action object
     */
    cta: customPropTypes.linkPropType,
    /**
     * Array of award objects
     */
    awards: PropTypes.arrayOf(
        PropTypes.shape({
            image: PropTypes.shape({ ...Image.propTypes }),
        }),
    ),
};

export default AwardsBanner;
